.vis-skills-beeswarm text {
  font-family: inherit;
  font-size: 0.75rem;
}

.vis-skills-beeswarm > * + * {
  margin-top: 1rem;
}

.vis-skills-beeswarm .chart-container {
  position: relative;
  font-size: 0.75rem;
  line-height: 1.2;
}

.vis-skills-beeswarm .chart-container > svg {
  display: block;
  overflow: visible;
}

.vis-skills-beeswarm .overall-background-rect {
  fill: #f5f5f5;
}

.vis-skills-beeswarm .axis-title {
  font-weight: bold;
}

.vis-skills-beeswarm .y-axis line {
  stroke: #e0e0e0;
}

.vis-skills-beeswarm .axis-div .tick {
  position: absolute;
  left: 0;
  top: 0;
  width: 112px;
}

.vis-skills-beeswarm .axis-div .tick-label {
  transform: translate(0, -50%);
  font-weight: bold;
}

.vis-skills-beeswarm .axis-div .tick-label.clickable {
  cursor: pointer;
}

.vis-skills-beeswarm .node-value {
  cursor: default;
  pointer-events: none;
}

.vis-skills-beeswarm .node-circle:hover {
  stroke: currentColor;
}

.vis-skills-beeswarm .means-g {
  pointer-events: none;
}

.vis-skills-beeswarm .tooltip-table {
  border-collapse: collapse;
  margin: 0 -1rem;
}

.vis-skills-beeswarm td {
  padding: 0 1rem;
  vertical-align: top;
}

.vis-skills-beeswarm .vis-tooltip-content {
  padding: 1rem;
}

.vis-skills-beeswarm .tooltip-section {
}

.vis-skills-beeswarm .tooltip-key {
  max-width: 20em;
}

.vis-skills-beeswarm .tooltip-value {
}

.vis-skills-beeswarm .font-weight-bold {
  font-weight: bold;
}

.vis-skills-beeswarm .text-right {
  text-align: right;
}

.vis-skills-beeswarm .text-uppercase {
  text-transform: uppercase;
}

.vis-skills-beeswarm .text-small {
  font-size: 0.75rem;
}

.vis-skills-beeswarm .text-secondary {
  opacity: 0.6;
}

.vis-skills-beeswarm .pt-2 {
  padding-top: 0.5rem;
}
