.vis-togglable-chips {
  display: flex;
  flex-flow: row wrap;
  margin: -0.25rem -0.5rem;
}

.vis-togglable-chips .vis-chip {
  margin: 0.25rem 0.5rem;
  font-size: 0.875rem;
  height: 2rem;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.vis-togglable-chips .vis-chip:hover {
  opacity: 0.72;
}

.vis-togglable-chips .vis-check {
  width: 1.5rem;
  height: 1.5rem;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0 0.375rem 0 0.25rem;
  cursor: pointer;
}

.vis-togglable-chips .vis-check > svg {
  display: block;
  width: 100%;
  height: 100%;
}

.vis-togglable-chips .vis-check-circle {
  fill: #fff;
}

.vis-togglable-chips .vis-chip-label {
  margin-right: 0.75rem;
}
